import jQuery from "jquery";

window.$ = jQuery;
window.jQuery = jQuery;
import angular from "angular";

window.angular = angular;

import moment from "moment";

window.moment = moment;

import "angular-scroll";
import "angular-sanitize";
import "angularjs-datepicker";
import "angular-animate";

require("./vendor/SweetAlert");
require("./vendor/sweetalert.min");
require("./vendor/airlst-sdk-1.10.0");
require("./vendor/sticky-header");
require("ng-image-gallery");
// require('./vendor/angular-locale_de-de');
require("angular-moment-picker");

require("./airlst.js");

angular
    .module("app", [
        "oitozero.ngSweetAlert",
        "AirLST-SDK",
        "duScroll",
        "ngSanitize",
        "thatisuday.ng-image-gallery",
        "720kb.datepicker",
        "moment-picker"
    ])
    .directive("ngEnter", [
        function () {
            return function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.vm.loadRsvp();
                        });

                        event.preventDefault();
                    }
                });
            };
        }
    ])
    .value("duScrollOffset", 110)

    .controller("AirLSTCtrl", [
        "$scope",
        "$http",
        "$location",
        "SweetAlert",
        "$document",
        "AirLSTSdkService",
        "$sce",
        function (
            $scope,
            $http,
            $location,
            SweetAlert,
            $document,
            AirLSTSdkService,
            $sce
        ) {
            var vm = this;

            vm.hasError = false;
            vm.loading = true;
            vm.submitting = false;
            vm.currentView = "registration";
            vm.tab = "code";
            vm.hasCode = false;
            vm.menucheckbox = false;
            vm.companion = false;
            vm.guestAnrede = "";
            vm.mobileNavIsOpen = false;
            vm.timeCustom_4 = "";
            vm.timeCustom_9 = "";
            vm.guestArrivalTimes = [];
            vm.guestDepartureTimes = [];

            var anmeldung = angular.element(document.getElementById("anmeldung"));

            vm.setTab = function (tabId) {
                // $document.scrollToElementAnimated(anmeldung);
                vm.tab = tabId;
                $scope.$applyAsync();
            };
            vm.isSet = function (tabId) {
                return vm.tab === tabId;
                $scope.$applyAsync();
            };

            vm.openDataPrivacy = function () {
                vm.overlay = true;
                vm.dataPrivacy = true;
                // window.scroll(0, 0);
            };
            vm.openImprint = function () {
                this.overlay = true;
                this.imprint = true;
                // window.scroll(0, 0);
            };

            vm.closeOverlay = function () {
                vm.overlay = false;
                vm.dataPrivacy = false;
                vm.imprint = false;
            };

            /**
             * Initializes the application and loads all necessary data from the server
             *
             * @private
             */
            vm._init = function () {
                AirLSTSdkService.companyUid = "R166MMM";
                AirLSTSdkService.guestlistUid = "ILWD3HNRMV";
                AirLSTSdkService.apiUrl = "https://v1.api.airlst.com/lp";

                vm._resetRsvpInformation();
                vm._resetRsvpCode();
                vm._resetAnswerType();
                vm._initCountDown();
                vm._retrieveEventInformation();
            };

            // Public functions

            /**
             * Queries the airlst server for a rsvp
             *
             * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. If the API responds with an rsvp,
             * the 'content' view will be loaded next.
             *
             * If an error occurs during the API call, rsvp information is reset an error message will be displayed.
             *
             * @returns {PromiseLike<T | never> | Promise<T | never> | *}
             */
            vm.loadRsvp = function () {
                vm.loading = true;
                return AirLSTSdkService.retrieveRsvpInformation(vm.rsvpCode).then(
                    function (rsvpInfo) {
                        vm.rsvpInformation = vm._prepareRsvpInformationFromApi(rsvpInfo);
                        vm.loading = false;

                        vm.hasCode = true;

                        switch (vm._checkRsvpActionOnUrl()) {
                            case "cancel":
                                vm.cancelRsvp();
                                break;
                            default:
                                vm.currentView = "welcome";
                                vm.setTab("generalForm");
                                break;
                        }
                    },
                    function (error) {
                        vm.loading = false;
                        vm.hasError = true;
                        vm._resetRsvpInformation();

                        switch (error.identifier) {
                            case "rsvp_not_found":
                                SweetAlert.swal(
                                    "Code not found",
                                    "Your code could not be found. Please contact the guest management (guestmanagement@bb-onsite.de)",
                                    "error"
                                );
                                break;
                            case "re_login_not_allowed":
                                SweetAlert.swal(
                                    "Code ungültig",
                                    "Ihr Code wurde bereits verwendet. Bitte wenden Sie sich an Ihren Ansprechpartner.",
                                    "error"
                                );
                                break;
                            default:
                                SweetAlert.swal(
                                    "Error",
                                    "Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte versuchen Sie es erneut.",
                                    "error"
                                );
                                break;
                        }
                    }
                );
            };

            /**
             * Cancels a rsvp
             *
             * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
             * pass validation.
             *
             * After successful cancellation, the form is reset, a success message is displayed and the 'code' view will
             * be shown next.
             *
             * If an error occurs during the API call an error message is displayed.
             *
             * @param form
             * @param form
             */
            vm.cancelRsvp = function (form) {
                if (form && !vm._validateRegistrationForm(form)) {
                    return;
                }

                vm.submitting = true;
                AirLSTSdkService.updateRsvpByCodeAndStatus(
                    vm.rsvpCode,
                    "cancelled",
                    vm._prepareRsvpInformationForApi(),
                    "cancelled"
                ).then(
                    function (rsvpInfo) {
                        vm.submitting = false;

                        SweetAlert.swal(
                            "Your cancellation",
                            "We are sorry that you can not attend.",
                            "success"
                        );

                        vm.goToView("registration");
                        vm.setTab("done");
                    },
                    function (error) {
                        vm.hasError = true;
                        vm.submitting = false;

                        switch (error.identifier) {
                            case "validation":
                                SweetAlert.swal(
                                    "Daten fehlerhaft",
                                    "Bitte überprüfen Sie Ihre Daten.",
                                    "error"
                                );
                                break;
                            case "rsvp_not_found":
                            default:
                                SweetAlert.swal(
                                    "Fehler",
                                    "Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.",
                                    "error"
                                );
                                break;
                        }
                    }
                );
            };

            /**
             * Confirms a rsvp
             *
             * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
             * pass validation.
             *
             * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
             * be shown next.
             *
             * If an error occurs during the API call an error message is displayed.
             *
             * @param form
             */
            vm.confirmRsvp = function (form) {
                if (!vm._validateRegistrationForm(form)) {
                    return;
                }
                var status = "confirmed";

                if (
                    vm.eventInformation.pax_limit_information.code.waitlist &&
                    vm.eventInformation.pax_limit_information.code.free_slots == 0
                ) {
                    status = "waitlisted";
                }
                vm.submitting = true;
                AirLSTSdkService.updateRsvpByCodeAndStatus(
                    vm.rsvpCode,
                    status,
                    vm._prepareRsvpInformationForApi(),
                    status
                ).then(
                    function (rsvpInfo) {
                        // vm._init();
                        vm.submitting = false;

                        // if (status == 'confirmed') {
                        //     SweetAlert.swal('Ihre Zusage', 'Vielen Dank für Ihre Anmeldung. In Kürze erhalten Sie die Anmeldebestätigung an Ihre E-Mail-Adresse. ', 'success');
                        // } else if (status == 'waitlisted') {
                        //     SweetAlert.swal('Warteliste', 'Vielen Dank für Ihren Eintrag auf die Warteliste. In Kürze erhalten Sie die Anmeldebestätigung an Ihre E-Mail-Adresse. ', 'success');
                        // }

                        vm.setTab("done");
                    },
                    function (error) {
                        vm.hasError = true;
                        vm.submitting = false;

                        switch (error.identifier) {
                            case "validation":
                                SweetAlert.swal(
                                    "Daten fehlerhaft",
                                    "Bitte überprüfen Sie Ihre Daten.",
                                    "error"
                                );
                                break;
                            case "rsvp_not_found":
                            default:
                                SweetAlert.swal(
                                    "Fehler",
                                    "Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.",
                                    "error"
                                );
                                break;
                        }
                    }
                );
            };

            /**
             * Navigates to a different page
             *
             * @param viewToGoTo
             */
            vm.goToView = function (viewToGoTo) {
                vm.currentView = viewToGoTo;
                $scope.$applyAsync();
            };

            /**
             * Sets answer type
             * Is used as a helper for the landingpage to display different views
             *
             * Possible Values: undifined, cancel, confirmed
             * @param newAnswerType
             */
            vm.setAnswerType = function (newAnswerType) {
                vm.answerType = newAnswerType;
            };

            /**
             * Adds an empty contact and rsvp object in the Recommendation array
             */
            vm.addRecommendation = function () {
                vm.rsvpInformation.recommendations.push({
                    rsvp: {
                        custom_1: vm.rsvpInformation.rsvp.custom_1,
                        custom_2: vm.rsvpInformation.rsvp.custom_2,
                        custom_3: vm.rsvpInformation.rsvp.custom_3,

                        custom_4: vm.rsvpInformation.rsvp.custom_4,

                        custom_5: vm.rsvpInformation.rsvp.custom_5,
                        custom_6: vm.rsvpInformation.rsvp.custom_6,
                        custom_7: vm.rsvpInformation.rsvp.custom_7,
                        custom_8: vm.rsvpInformation.rsvp.custom_8,
                        custom_9: vm.rsvpInformation.rsvp.custom_9,
                        custom_10: vm.rsvpInformation.rsvp.custom_10,
                        custom_11: vm.rsvpInformation.rsvp.custom_11,
                        custom_12: vm.rsvpInformation.rsvp.custom_12,
                        custom_13: vm.rsvpInformation.rsvp.custom_13
                    },
                    contact: {
                        country: vm.rsvpInformation.contact.country
                    }
                });

                vm.recommendationArrivalTimes.push({
                    hour: vm.rsvpInformation.rsvp.custom_4,
                    minute: vm.timeCustom_4
                });

                vm.recommendationDepartureTimes.push({
                    hour: vm.rsvpInformation.rsvp.custom_9,
                    minute: vm.timeCustom_9
                });
            };

            /**
             * Deletes the given guest object from the Recommendation array
             *
             * @param guest
             */
            // vm.removeRecommendation = function (recommendation) {
            //     var index = vm.rsvpInformation.recommendations.indexOf(recommendation);
            //
            //     vm.rsvpInformation.recommendations.splice(index, 1);
            //     vm.guestArrivalTimes.splice(index, 1);
            //     vm.guestDepartureTimes.splice(index, 1);
            // };

            /**
             * Adds an empty contact and rsvp object in the guests array
             */
            vm.addGuest = function () {
                vm.rsvpInformation.guests.push({
                    rsvp: {
                        custom_2: vm.rsvpInformation.rsvp.custom_2,
                        custom_3: vm.rsvpInformation.rsvp.custom_3,
                        custom_4: vm.rsvpInformation.rsvp.custom_4,
                        custom_5: vm.rsvpInformation.rsvp.custom_5,
                        custom_6: vm.rsvpInformation.rsvp.custom_6,
                        custom_7: vm.rsvpInformation.rsvp.custom_7,
                    },
                    contact: {
                        country: vm.rsvpInformation.contact.country
                    }
                });

                vm.guestArrivalTimes.push({
                    hour: vm.timeCustom_4,
                    minute: vm.rsvpInformation.rsvp.custom_4
                });

                vm.guestDepatrureTimes.push({
                    hour: vm.timeCustom_9,
                    minute: vm.rsvpInformation.rsvp.custom_9
                });
            };

            /**
             * Deletes the given guest object from the guest array
             *
             * @param guest
             */
            vm.removeGuest = function (guest) {
                var index = vm.rsvpInformation.guests.indexOf(guest);

                if (index > -1) {
                    vm.rsvpInformation.guests.splice(index, 1);
                    vm.guestArrivalTimes.splice(index, 1);
                    vm.guestDepartureTimes.splice(index, 1);
                }
            };

            vm.manageGuest = function () {
                if (vm.rsvpInformation.guests.length > 0) {
                    vm.removeGuest(vm.rsvpInformation.guests[0]);
                } else {
                    vm.addGuest();
                }
            };

            /**
             * Checks if the current URL contains the rsvp_code GET parameter and tries to load the rsvp by the
             * supplied parameter
             *
             * @private
             */

            vm._checkRsvpCodeOnUrl = function () {
                var match = $location.absUrl().match(/rsvp_code=([A-Za-z0-9]+)/);

                if (match && match.length >= 2 && match[1]) {
                    vm.rsvpCode = match[1];
                    vm.loadRsvp();
                } else {
                    vm.loading = false;
                }
            };

            /** POinti doc */
            vm._checkRsvpActionOnUrl = function () {
                var match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

                if (match && match.length >= 2 && match[1]) {
                    return match[1];
                } else {
                    return false;
                }
            };

            /**
             *
             * Validates the supplied form
             *
             * @param form
             * @returns {boolean}
             * @private
             */
            vm._validateRegistrationForm = function (form) {
                vm.formValidationReg = true;

                if (!form.$valid) {
                    SweetAlert.swal(
                        "Missing data",
                        "Please complete all mandatory fields.",
                        "warning"
                    );
                    return false;
                } else {
                    vm.stage++;
                    vm.formValidationReg = false;
                    $document.scrollToElementAnimated(anmeldung);
                    return true;
                }
            };

            // multi step form for REGISTRATION
            vm.stage = "";
            vm.formValidationReg = false;

            // Navigation functions

            vm.backStep = function () {
                vm.stage--;
                $document.scrollToElementAnimated(anmeldung);
            };

            vm.nextStep = function () {
                vm._validateRegistrationForm(vm.registrationForm);
            };

            /**
             * Populates vm.eventInformation with guestlist data from the server
             *
             * @private
             */
            vm._retrieveEventInformation = function () {
                vm.eventInformation = null;

                AirLSTSdkService.retrieveGuestlistInformation().then(
                    function (eventInformation) {
                        vm.eventInformation = eventInformation;
                        $scope.$applyAsync();
                    },
                    function () {
                        vm.hasError = true;

                        SweetAlert.swal(
                            "Fehler",
                            "Die Informationen zum gewünschten Event konnten nicht geladen werden",
                            "error"
                        );
                    }
                );
            };

            /**
             * Returns rsvp data ready to be submitted
             *
             * @returns {*|null}
             * @private
             */
            vm._prepareRsvpInformationForApi = function () {
                if(vm.rsvpInformation.rsvp.custom_4) {
                    vm.rsvpInformation.rsvp.custom_4 = vm.timeCustom_4 + ":" + vm.rsvpInformation.rsvp.custom_4;
                }
                if(vm.rsvpInformation.rsvp.custom_9) {
                    vm.rsvpInformation.rsvp.custom_9 = vm.timeCustom_9 + ":" + vm.rsvpInformation.rsvp.custom_9;
                }
                _.each(vm.rsvpInformation.guests, function (value, index) {
                    vm.rsvpInformation.guests[index].rsvp.custom_4 = vm.guestArrivalTimes[index].hour + ":" + vm.guestArrivalTimes[index].minute;
                    vm.rsvpInformation.guests[index].rsvp.custom_9 = vm.guestDepartureTimes[index].hour + ":" + vm.guestDepartureTimes[index].minute;
                });

                return vm.rsvpInformation;
            };

            /**
             * Transforms rsvp data supplied by the API
             *
             * @param inData
             * @returns {*}
             * @private
             */

            vm._prepareRsvpInformationFromApi = function (inData) {
                inData.contact.sex = inData.contact.sex !== "unknown" ? inData.contact.sex : "";
                inData.contact.country = inData.contact.country ? inData.contact.country : null;
                inData.rsvp.custom_1 = inData.rsvp.custom_1 ? inData.rsvp.custom_1 : null;
                inData.rsvp.custom_2 = inData.rsvp.custom_2 ? inData.rsvp.custom_2 : null;
                inData.rsvp.custom_3 = inData.rsvp.custom_3 ? inData.rsvp.custom_3 : null;
                inData.rsvp.custom_7 = inData.rsvp.custom_7 ? inData.rsvp.custom_7 : null;
                inData.rsvp.custom_6 = inData.rsvp.custom_6 ? inData.rsvp.custom_6 : null;
                inData.rsvp.custom_10 = inData.rsvp.custom_10 ? inData.rsvp.custom_10 : null;
                inData.rsvp.custom_11 = inData.rsvp.custom_11 ? inData.rsvp.custom_11 : null;
                inData.rsvp.custom_12 = inData.rsvp.custom_12 ? inData.rsvp.custom_12 : null;
                inData.rsvp.custom_15 = inData.rsvp.custom_15 ? inData.rsvp.custom_15 : null;
                inData.rsvp.custom_4 = inData.rsvp.custom_4 ? inData.rsvp.custom_4 : '';

                if (inData.rsvp.custom_4) {
                    var tempCustom_4 = inData.rsvp.custom_4.split(":")[1];
                    vm.timeCustom_4 = inData.rsvp.custom_4.split(":")[0];
                    inData.rsvp.custom_4 = tempCustom_4;
                }
                if (inData.rsvp.custom_9) {
                    var tempCustom_9 = inData.rsvp.custom_9.split(":")[1];
                    vm.timeCustom_9 = inData.rsvp.custom_9.split(":")[0];
                    inData.rsvp.custom_9 = tempCustom_9;
                }

                _.each(inData.guests, function (value, index) {
                    vm.guestArrivalTimes[index] = {
                        hour: value.rsvp.custom_4.split(":")[0],
                        minute: value.rsvp.custom_4.split(":")[1]
                    };
                    vm.guestDepartureTimes[index] = {
                        hour: value.rsvp.custom_9.split(":")[0],
                        minute: value.rsvp.custom_9.split(":")[1]
                    };
                });

                return inData;
            };

            vm._resetRsvpInformation = function () {
                vm.rsvpInformation = null;
            };

            vm._resetRsvpCode = function () {
                vm.rsvpCode = "";
            };

            vm._resetAnswerType = function () {
                vm.answerType = "undefined";
            };

            vm._initCountDown = function () {
                vm.countdownTime =
                    (new Date(2020, 0, 31, 14, 0, 0) - new Date()) / 1000;
            };

            vm._init();
            vm._checkRsvpCodeOnUrl();
        }
    ]);
